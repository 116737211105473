import React, { useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Image, Placeholder } from "cloudinary-react"
import data from "../../../config.json"

const NuvoImage = ({
  publicId,
  alt = "",
  quality,
  wrapperClassName,
  className,
  useAR = true,
  children,
  hasLoaded,
  placeholder = true,
  ...rest
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  if (!publicId) return <></>

  // default image object if match isn't found
  let image = { public_id: publicId, context: { custom: { alt: alt } } }

  // find image object
  const match = useMemo(
    () => data.images.resources.find(node => node.public_id === publicId),
    [data, publicId]
  )

  if (match) {
    image = match
  }

  const imageQuality = quality ?? 99

  const imageWrapperClasses = classNames(wrapperClassName, {
    "image-meta__wrapper": true
  })

  const imageClasses = classNames(className, {
    "image-meta__image": true
  })

  const handleLoaded = () => {
    setIsLoaded(true)

    if (hasLoaded) {
      hasLoaded(image.public_id)
    }
  }

  return (
    <div className={imageWrapperClasses}>
      <img
        src={`/${image.public_id}.${image.format}`}
        alt={image.context.custom.alt}
        className={imageClasses}
      />
    </div>
  )
}

NuvoImage.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
  useAR: PropTypes.bool,
  wrapperClassName: PropTypes.string
}

export default React.memo(NuvoImage)

NuvoImage.defaultProps = {
  cloudName: "nuvolum",
  width: "auto"
}
