import { Link, navigateTo, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReviewRow from "./ReviewRow"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

var classNames = require("classnames")

function ReviewRowsSection(props) {
  var reviewRows = props.reviews.map(review => {
    return (
      <ReviewRow
        key={review.title}
        name={review.reviewerName}
        nameSingular={props.nameSingular}
        quote={review.quote}
        url={review.title}
        photo={review.thumbnailPublicId}
        gender={review.gender}
      />
    )
  })

  return (
    <section
      className={`body-sections section ${props.classnames}`}
      style={{
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom
      }}
    >
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <h3 className="review-rows-heading">
            Reviews From Our {props.nameSingular} Patients
          </h3>
        </div>
        <div className="column is-4"></div>
      </div>

      <div style={{ paddingTop: "32px" }}>{reviewRows}</div>
    </section>
  )
}

ReviewRowsSection.propTypes = {
  reviews: PropTypes.array
}

export default ReviewRowsSection
